.lotsize-input label{
    color:#000 !important;
}
.lotsize-input div{
    max-width: 700px;
    display: flex;
    margin: 0 auto;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 25px;
}

.lotsize-input{
    margin-top: 100px;
    color: #fff;
}
.lotsize-input ul{
    list-style: none;
    width: 300px;
    margin: 15px auto;
}
.lotsize-input h4,.lotsize-input h3{
    width: 300px;
    margin: 10px auto;
}
.lotsize-input li{
    text-align: left;
}
.lotsize-input h3{
    background: linear-gradient(to right, #66118E, #EA1B85); /* Adjust colors as needed */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}
.lotsize-input input{
    margin-left: 20px;
}