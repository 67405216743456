.overview-container {
    text-align: center;
    background-color: #FFFFFFF2;
    padding: 10px 0;
    min-height: 100vh;
}

.first_Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgba(12, 11, 11, 1);
}

.first_Container>ul:first-child {
    text-align: left;
}

.first_Container>ul:last-child {
    text-align: right;
}

.profit_loss_winrate {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.first_Container ul {
    list-style: none;
    padding: 0;
}

.titleBox {
    color: rgba(12, 11, 11, 1);
    text-align: left;
}

.winrate_num {
    font-size: 40px;
    margin: 0;
    font-weight: bold;
    color: rgba(12, 11, 11, 1);
}

.title_target_loss {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    height: 100%;
    color: rgba(12, 11, 11, 1);
}

.contain-win-loss {
    display: flex;
    justify-content: space-evenly;
    color: rgba(12, 11, 11, 1);
    align-items: center;
}

.all-card-overview {
    display: flex;
    flex-wrap: wrap;
}

.certificate-container {
    display: flex;
    justify-content: center;
}

.contain-dh {
    background-color: #e9ecef;
    min-height: 100vh;
}

.withdrawal-content {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 15px;
    flex-direction: column;
}

.history-withdrawal {
    width: 100%;
    margin-top: 25px;
    padding: 10px;
}

.request-withdrawal,
.update-withdrawal {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 10px;
    width: 46%;
    margin: 1%;
}

.container-withdrawal {
    width: 100%;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 1200px) {
    /*Tablets [601px -> 1200px]*/
}

@media only screen and (max-width: 600px) {
    /*Big smartphones [426px -> 600px]*/
}

@media only screen and (max-width: 500px) {

    .container-withdrawal {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .request-withdrawal,
    .update-withdrawal {
        width: 100%;
    }


}