.checkoutcontainer{
    background-color: #2D3971;
    background-image: url("../img/bgcheckout.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.checkout-content{
    padding-top: 100px;
    display: flex;
    align-items: center;
    justify-content:center;
    flex-wrap: wrap;
    flex-direction: column;
}
.Cart-checkout{
    background-color: #6763639e;
    padding: 50px 15px;
    border-radius: 25px;
    margin: 15px;
    
}
.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
    height: 300px !important; /* Adjust as needed */
    width: 300px !important;
  }
.table-cart{
    text-align: left;
    width: 95%;
    margin: 25px 0px;
}
/* .table-cart th{
    width: 90%;
    margin: 10%;
} */
.table-cart td{
    margin: 0px 25px ;
}
.btns-cart{
    width: 300px;
}

.apply-coupon{
    height: 50px;
}
/* .applywitdth{
    width: 90px;
} */
.coupon-cart input{
    height: 17px  !important;
    /* margin: 10px !important; */

}
.coupon-cart{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
    gap: 10px;
}

.referralcode-cart input{
  width: 270px !important;
  height: 17px  !important;
}
.payment-btn{
    display: flex;
    flex-direction: column;
    height: 140px;
    justify-content: space-evenly;
}
.cryptopaymentbtn{
    background-color: #2D3971 !important;
    width: 100%;
}
.cardpaymentbtn{
    background-color: rgba(237, 195, 143, 0.66) !important;
}
.checkout-details{
    max-width: 600px;
    min-width: 320px;
}
/* .details-input-checkout{

} */
.name-detail-checkout{
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.email-details-checkout{
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: space-evenly;
}
.inputEmailcheckout{
    color: #000000 !important;
    border-color: #fff !important;
    background-color : #ffffffd8 !important; 
    border-radius: 5px !important;
    margin: 0 10px !important;
  }
  .checkout-title{
    font-size: 3em;
    background: linear-gradient(to right, #66118E, #EA1B85); /* Adjust colors as needed */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin: 10px;
  }

  .crypto-contain{
    max-width: 1000px;
    min-width: 300px;
    background-color: #fff;
    padding: 25px;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    border-radius: 25px;
  }
  .QR-code{
    box-shadow: 1px 1px 10px #07070774;
    width: 250px;
    height: 250px;
    background-color: #ffffff;
    display: grid;
    place-items: center;
    border-radius: 15px;
    margin: 15px;
    padding: 15px;
    margin: 0 auto;
  }
  /* .input-crypto-address{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    font-size: 10px !important;

  } */
  .copy-btn-address{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  .address-container{
    display: flex;
    justify-content: flex-start;
    margin: 25px;
    width: 250px;
  }
  .user-details{
    width:300px;
    padding: 0px 20px;
  }
  .pending-payment-crypto{
    width: 250px;
    margin: 0 auto;
    padding: 0px 15px;
  }
  .input-crypto-address input{
    font-size: 15px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .stripePayment{
    /* background-color: #fff !important; */
    max-width: 600px;
    margin: 100px auto;
    padding: 50px;
    /* color: #fff; */
  }
  /* .stripe{
    margin-top: 500px;
  } */
  .stripePayment{
    color: #fff;
  }
  .successfully{
    background-color: #2D3971;
    height: 80vh;
    padding: 200px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    font-weight: bolder;
  }
  .successfully div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    font-weight: bolder;
  }
  .paid-by-stripe{
    max-width: 1000px;
    margin: 100px auto;
    background-color: #2D3971 !important;
  }