.faq-container{
    background-color: #0E1120;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
}
.step-container{
    max-width: 1000px;
    margin: 30px 0px 10px 10px;

}
.step-container h1{
    font-size: 3em;
    background: linear-gradient(to right, #66118E, #EA1B85); /* Adjust colors as needed */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

}