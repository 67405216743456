.Affliate-container{
    background-color: #0E1120;
    height: 100vh !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;


}
.contain-affliate{
    height:fit-content;
    padding: 25px;
}
.Affliate-container h1, .affliate-dashboard-right h2{
    background: linear-gradient(to right, #66118E, #EA1B85); /* Adjust colors as needed */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}
.Affliate-container h1{
  font-size: 2em;
}
.affliate-dashboard-right h2{
  font-size: 1.5em;
}

/* #input-with-sx{
    color: #eaeaea !important;
    background-color: #93939346 !important;
    border-radius: 15px;
    height: 20px !important;
} */

.Affliate-container label{
    color: #fff !important;
}
.affliate-email-login{
    width: 80% !important;
    color: #eaeaea !important;
    background-color: #93939346 !important;
    border-radius: 5px;
    height: 50px;
    font-size: 18px;
    border: none;
    padding-left: 10px;

}
.login-field-affliate{
    display: flex;
    align-items: center;
    width: 100%;
    margin: 15px;
}
.login-btn-affliate{
    width: 100%  !important;
    height: 50px;
    margin-left: 2% !important;
    border-radius: 15px !important;
    background-color: #2D3971 !important;
}
.choice-btn-affliate{
    height: 50px;
    margin-top: 10px !important;
    border-radius: 15px !important;
    background-color: #2D3971 !important;
}
.choice-btn-withdraw{
  background-color: #1e105e !important;
}
.affliate-pw-login{
    width: 60%;
    color: #eaeaea !important;
    background-color: #93939346 !important;
    border-radius: 5px;
    height: 50px;
    font-size: 18px;
    border: none;
    padding-left: 10px;
}


/* beginning */
  .card, .cart {
    overflow: visible;
    max-width: 600px;
    height: 620px;
    margin: 100px auto;
  }
  
  .content {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 300ms;
    box-shadow: 0px 0px 10px 1px #000000ee;
    border-radius: 5px;
  }
  
  .back {
    background-color: #151515;
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden; 
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden; 
    -ms-backface-visibility: hidden; 
    -o-backface-visibility: hidden; 
    border-radius: 5px;
    overflow: hidden;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  
  
  .back::before {
    position: absolute;
    content: ' ';
    display: block;
    width: 160px;
    height: 160%;
    background: linear-gradient(90deg, transparent, #ff9966, #ff9966, #ff9966, #ff9966, transparent);
    animation: rotation_481 5000ms infinite linear;
  }
  
  .back-content {
    position: absolute;
    width: 99%;
    height: 99%;
    background-color: #151515;
    border-radius: 5px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  

  @keyframes rotation_481 {
    0% {
      transform: rotateZ(0deg);
    }
  
    0% {
      transform: rotateZ(360deg);
    }
  }
/* end */







.affliate-dashboard-container{
  display: flex;
  background-color: #0E1120;
  color: #fff;
  flex-wrap: wrap;
  max-width: 1000px;

  padding-bottom: 40vh;
  margin: 0 auto;
  height: fit-content;
}
.affliate-dashboard-left{
  width: 150px;
  height: 100px;

}
.affliate-dashboard-right{
  margin: 0 auto;
}
.affliate-dashboard-left ul{
  list-style: none;
}
.affliate-dashboard-right div:nth-child(1){
  display: flex;
  align-items: center;
  justify-content: center;
}
.affliate-dashboard-right div:nth-child(1) button{
  height: 50px !important;
  border-bottom-left-radius: 0  !important;
  border-top-left-radius: 0px !important;
}
.referral-input-dashboard{
  color: #fff !important;
  background-color: #fff;
}
.affliate-dashboard-right div:nth-child(1) label{
  color: #705ad4 !important;
}
.inputdiscountcode{
  color: #fff !important;
  border-color: #fff !important;
  background-color : #ffffffd8 !important; 
  border-bottom-right-radius: 0  !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  height: 50px !important;

  
}

.history-affliate-withdrawal{
  background-color: #2D3971;
  padding: 15px;
  margin: 25px;
  border-radius: 15px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform-style: preserve-3d;
    transition: transform 300ms;
    box-shadow: 0px 0px 10px 1px #000000ee;
    border-radius: 5px;
}

/* .history-affliate-withdrawal::before {
  position: absolute;
  content: ' ';
  display: block;
  width: 160px;
  height: 160%;
  background: linear-gradient(90deg, transparent, #ff9966, #ff9966, #ff9966, #ff9966, transparent);
  animation: rotation_481 5000ms infinite linear;
} */
.history-table-withdrawal th,.history-table-withdrawal td{
  width: 100px;
  text-align: left;
}
.currentbalance{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}

.affiate-dashboard-menu li:hover{
  cursor: pointer;
  color: #66118E;
}