.Support-container{
    background-color: #0b0e16;
    height: 100vh;
    padding-top: 50px;
    width: 100vw;
}
.main-support-container{
    background-color: #0b0e16;
    display: flex;
    justify-content:flex-end;
    align-items: center;
    flex-direction: column;
}
.contactus-email{
    color: #fff;
    margin: 50px;
}
.contactus-email div{
    display: grid;
    place-items: center;
    grid-template-columns: 30% 70%;
    max-width: 300px;
}
.contactus-email p{
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to right, #66118E, #EA1B85); /* Adjust colors as needed */
    border-radius: 50%;
    margin: 10px;
}